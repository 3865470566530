import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SendApi from "../services/SendApi";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

function BuyNumber({ number }) {
  const [selectedPayment, setSelectedPayment] = useState("cash");
  const [selectedCount, setSelectedCount] = useState(null);
  const navigate = useNavigate();

  const handleCountClick = (count) => {
    setSelectedCount(count);
  };

  const handlePaymentClick = (payment) => {
    setSelectedPayment(payment);
    if (payment === "taksit") {
      if (selectedCount === null) {
        setSelectedCount(6);
      }
    } else {
      setSelectedCount(null);
    }
  };

  const payMethodAz = [
    {
      cash: "Qapıda ödəmə",
      online: "Onlayn ödəmə",
      taksit: "Hissəli ödəmə",
    },
  ];

  const [user, setUser] = useState("");
  useEffect(() => {
    const userLS = localStorage.getItem("user");
    if (userLS) {
      const decodedUser = jwtDecode(JSON.parse(userLS));
      setUser(decodedUser.data[0]);
    } else {
      setUser();
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    if (user) {
      setformdata((prevFormdata) => ({
        ...prevFormdata,
        name: user.name || "",
        fin: user.fin || "",
      }));
    }
  }, [user]);

  const [formdata, setformdata] = useState({
    name: "",
    serial: "",
    fin: "",
    contact: "",
    message: "",
  });

  const dataToSend = {
    ...formdata,
    paymentMethod: selectedPayment,
    instalmentCount: selectedCount,
    number: number,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const resp = await SendApi({
      apiUrl: "https://mynumber.az/api/checkOrder.php",
      data: dataToSend,
    });
    if (resp && resp[0] && resp[0].id) {
      const id = resp[0].id;
      const fin = resp[0].fin;
      const applyElement = document.getElementById("applyNow");
      if (applyElement) {
        applyElement.classList.remove("show");
        applyElement.setAttribute("aria-modal", "false");

        applyElement.removeAttribute("role");
        const backdropElement = document.querySelector(
          ".modal-backdrop.fade.show"
        );
        if (backdropElement) {
          backdropElement.remove();
        }

        document.body.classList.remove("modal-open");
      }
      navigate(`/track/${fin}/${id}`);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  İndi nömrə sifariş etmək daha rahat
                </h5>
                <h5 style={{ color: "#766df4" }}>0{number.sim}</h5>
              </div>
              <div className="">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span className="badge bg-soft-success fs-13 mt-1">
                      {number.price} ₼
                    </span>
                  </li>

                  {selectedPayment !== null && (
                    <li className="list-inline-item">
                      <span className="badge bg-soft-purple fs-13 mt-1">
                        {payMethodAz[0][selectedPayment]}
                      </span>
                    </li>
                  )}

                  {selectedCount !== null && (
                    <li className="list-inline-item">
                      <span className="badge bg-soft-danger fs-13 mt-1">
                        {selectedCount} ay
                      </span>
                    </li>
                  )}
                </ul>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={selectedPayment === "cash" ? handleSubmit : undefined}
                action={
                  selectedPayment === "online" || selectedPayment === "taksit"
                    ? "https://mynumber.az/api/kb.php"
                    : undefined
                }
                method="post"
              >
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    name="name"
                    placeholder="Ad və soyadınızı daxil edin"
                    value={formdata.name}
                    onChange={(e) =>
                      setformdata({ ...formdata, name: e.target.value })
                    }
                    disabled={user && user.name ? true : false}
                    minLength="2"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="serialContropInput"
                    name="serial"
                    placeholder="Seriya nömrəsi"
                    value={formdata.serial}
                    onChange={(e) =>
                      setformdata({ ...formdata, serial: e.target.value })
                    }
                    minLength="7"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="finControlInput"
                    name="fin"
                    placeholder="Fin kod"
                    value={formdata.fin}
                    onChange={(e) =>
                      setformdata({ ...formdata, fin: e.target.value })
                    }
                    disabled={user && user.fin ? true : false}
                    minLength="7"
                    maxLength="7"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="contactControlInput"
                    name="contact"
                    placeholder="Əlaqə nömrəsi"
                    value={formdata.contact}
                    onChange={(e) =>
                      setformdata({ ...formdata, contact: e.target.value })
                    }
                    minLength="9"
                    required
                  />
                </div>
                <input
                  type="text"
                  name="price"
                  className="d-none"
                  value={number.price}
                  readOnly
                />
                <input
                  type="text"
                  name="id"
                  className="d-none"
                  value={number.id}
                  readOnly
                />
                <input
                  type="text"
                  name="payMethod"
                  className="d-none"
                  value={selectedPayment}
                  readOnly
                />
                <input
                  type="text"
                  name="taksit"
                  className="d-none"
                  value={selectedCount}
                  readonly
                />
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="2"
                    placeholder="Mesajınız"
                    value={formdata.message}
                    onChange={(e) =>
                      setformdata({ ...formdata, message: e.target.value })
                    }
                  ></textarea>
                </div>
                <p className="select-pay">Ödəniş üsülunu seçin</p>
                <Swiper
                  pagination={{
                    clickable: true,
                    dynamicBullets: true,
                  }}
                  slidesPerView={1.2}
                  slidesPerGroup={1}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="paySwiper"
                >
                  <SwiperSlide>
                    <div
                      onClick={() => handlePaymentClick("cash")}
                      className={`paymentMethod cash ${
                        selectedPayment === "cash" || selectedPayment === null
                          ? "select"
                          : ""
                      }`}
                    >
                      <img
                        src="assets/images/payMethod/cash.svg"
                        alt="Qapıda ödəmə üsulu"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      onClick={() => handlePaymentClick("online")}
                      className={`paymentMethod online ${
                        selectedPayment === "online" ? "select" : ""
                      }`}
                    >
                      <img
                        src="assets/images/payMethod/online.svg"
                        alt="Onlayn ödəmə üsulu"
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      onClick={() => handlePaymentClick("taksit")}
                      className={`paymentMethod taksit ${
                        selectedPayment === "taksit" ? "select" : ""
                      }`}
                    >
                      <img
                        src="assets/images/payMethod/tacsitKb.svg"
                        alt="Hissəli ödəmə üsulu"
                      />
                      <div className="instalment">
                        <div
                          onClick={() => handleCountClick(6)}
                          className={`count ${
                            selectedCount === 6 ||
                            (selectedCount === null &&
                              selectedPayment === "taksit")
                              ? "select"
                              : ""
                          }`}
                        >
                          <span>6</span>
                        </div>
                        <div
                          onClick={() => handleCountClick(12)}
                          className={`count ${
                            selectedCount === 12 ? "select" : ""
                          }`}
                        >
                          <span>12</span>
                        </div>
                        <div
                          onClick={() => handleCountClick(18)}
                          className={`count ${
                            selectedCount === 18 ? "select" : ""
                          }`}
                        >
                          <span>18</span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <button type="submit" className="btn mc-bg-purple w-100">
                  Sifarişi Təsdiqlə
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* END APPLY MODAL */}
    </div>
  );
}

export default BuyNumber;
